
.navbar{
  .container{
    align-items: flex-start;
    position: relative;
  }
  .navbar-brand{
    width: calc(100% - 75px);
    max-width: 300px;
    height: 100px;
    @extend .py-0;
    @include media-breakpoint-up(md){
      max-width: 320px;
    }
    svg{
      width: 100%;
      max-width: 310px;
      height: 100px;
      .bg{
        fill:$logo-bg;
      }
      .name{
        fill:$logo-name;
      }
      .title{
        fill:$logo-title;
      }
      .head{
        fill:$logo-body;
      }
      .body{
        fill:$logo-body;
      }
      .feet{
        fill:$logo-feet;
      }

    }
   img{
     max-width: 230px;
     width: 100%;
     height: auto;
   }
  }
  .navbar-toggler{
    position: absolute;
    right: 0;
    top: 8px;
  }
  .btn-social{
    font-size: rem-calc(30);
    line-height: normal;
  }
  .top-navigation{
    @extend .w-100;
    .btn{
      white-space: nowrap;
      @extend .mb-3;
      @extend .mr-md-1;
      width: 100%;

    }
  }
  .main-search-form{
    .input-group-text, .form-control{
      border: 0;
      background-color: $nav-search-form-bg;
      color: $nav-search-form-color;
      height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
      font-weight: bold;
      padding: 0;
      border-radius: 5px;
      &::placeholder {
        color: $nav-search-form-color;
      }
    }
    .gsc-control-cse, .gsc-input-box{
      border: none !important;
      background: transparent !important;
    }
    .gsc-search-box-tools .gsc-search-box .gsc-input{
      padding-right: 0 !important;
      height: 35px !important;
      background: transparent none !important;
      color: $white;
      &::placeholder{
        color: $black;
      }
    }
    .gsib_b{
      display: none !important;
    }
    .cse .gsc-control-cse, .gsc-control-cse{
      padding: 0 !important;
    }
    .cse .gsc-search-button-v2, .gsc-search-button-v2{
      border: 0 !important;
      padding: 10px !important;
      background: transparent !important;
    }
    .gsc-search-button-v2 svg{
      width: 20px;
      height: 20px;
    }
  }
  .main-navigation{
    width: 100%;
    li.nav-item:last-child { // prevent layout collapse in case the subscribe button (3rd part library) doesn't load properly
      max-width: 106px;
    }
    .nav-link{
      font-weight: bold;
      font-size: rem-calc(15);
      white-space: nowrap;
    }
    .dropdown-toggle{
      &:after{
        float: right;
        @include media-breakpoint-up(md){
          display: none;
        }
      }
    }
  }
  #mainNavbar{
    @include media-breakpoint-up(md){
      flex-direction: column;
    }
    .input-group{
      //width: auto;
    }
  }
  .navbar-nav{
    .nav-item{
      &.dropdown{
        &.show, &:hover{
          @include media-breakpoint-up(md){
            background-color: $dropdown-bg;
            border-top-left-radius: $dropdown-border-radius;
            border-top-right-radius: $dropdown-border-radius;
          }
          .nav-link{
            @include media-breakpoint-up(md){
              color: $dropdown-color;
            }

          }
        }
        .dropdown-menu{
          margin: 0;
          @include media-breakpoint-up(md){
            border-top-left-radius: 0;
            min-width: 250px;
          }
          .dropdown-item{
            @extend .py-2;
            font-weight: bold;
          }
        }
      }
    }

  }
  .dropdown:hover > .dropdown-menu {
    @include media-breakpoint-up(md){
      display: block;
    }

  }
  .dropdown > .dropdown-toggle:active {
    @include media-breakpoint-up(md){
      /*Without this, clicking will make it sticky*/
      pointer-events: none;
    }

  }
  .navbar .btn-social a {
    color: $theme-green !important;
  }
}

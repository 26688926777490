.btn{
  &-twitter{
   background-color: $twitter;
    color: $white;
  }
  &-pinterest{
    background-color: $pinterest;
    color: $white;
  }
  &-facebook{
    background-color: $facebook;
    color: $white;
  }
}
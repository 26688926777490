@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z7brpx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?z7brpx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?z7brpx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?z7brpx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?z7brpx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="bs-icon-"], [class*=" bs-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bs-icon-search {
  &:before {
    content: $bs-icon-search; 
  }
}
.bs-icon-cross {
  &:before {
    content: $bs-icon-cross; 
  }
}
.bs-icon-checkmark {
  &:before {
    content: $bs-icon-checkmark; 
  }
}
.bs-icon-facebook {
  &:before {
    content: $bs-icon-facebook; 
  }
}
.bs-icon-facebook2 {
  &:before {
    content: $bs-icon-facebook2; 
  }
}
.bs-icon-twitter {
  &:before {
    content: $bs-icon-twitter; 
  }
}
.bs-icon-youtube {
  &:before {
    content: $bs-icon-youtube; 
  }
}
.bs-icon-pinterest2 {
  &:before {
    content: $bs-icon-pinterest2; 
  }
}
.bs-icon-file-pdf {
  &:before {
    content: $bs-icon-file-pdf; 
  }
}
.bs-icon-arrow_right {
  &:before {
    content: $bs-icon-arrow_right; 
  }
}


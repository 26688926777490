.media{
  .media-icon{
    @extend .p-1;
    background-color: lighten($body-bg, 30%);
    font-size: rem-calc(30);
    color: $primary ;
  }
}

.news-resources{
  .media{
    align-items: center;
  }
}

.home-policies,
.home-newsletters,
.home-videos,
.home-podcasts {
  .media.mb-1 {
    border-width: 3px;
    border-style: solid;
    padding: calc(1rem - 2px);
    box-sizing: border-box;
    height: 100%;
  }
  img {
    max-width: 100%;
  }
  .media-body div:first-child {
    position: relative;
    &::before {
      display: block;
      position: absolute;
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="white" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"/></svg>');
      width: 2rem;
      height: 3rem;
      left: calc(50% - 1rem);
      top: calc(50% - 1.5rem);
    }
  }
}

.home-podcasts {
  h2, h3 {
    color: $logo-name;
  }
  .media.mb-1 {
    border-color: $logo-name;
  }
  .media-body div:first-child {
    width: 100%;
    padding-top: 54.5%;
    position: relative;
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
    &::before {
      //content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"/></svg>');
      //width: 3rem;
      //height: 3rem;
      //left: calc(50% - 1.5rem);
      //top: calc(50% - 1.5rem);
      //z-index: 1;
      display: none;
    }
  }
}
.home-newsletters {
  .media-body div:first-child {
    &::before {
      display: none;
    }
  }
}
.home-videos {
  h2, h3 {
    color: $primary;
  }
  .media.mb-1 {
    border-color: $primary;
  }
  .media-body div:first-child {
    &::before {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="white" d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"/></svg>');
      width: 3rem;
      height: 3rem;
      left: calc(50% - 1.5rem);
      top: calc(50% - 1.5rem);
    }
  }
}

.home-policies {
  h2, h3 {
    color: $text-color;
  }
  .media.mb-1 {
    border-color: $text-color;
  }
  .media-body div:first-child {
    &::before {
      display: none;
    }
  }
}

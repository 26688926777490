.accessibilityControls{
  .modal-header{
    @extend .my-3;
    @include media-breakpoint-down(md){
      display: block;
    }
    .modal-title{
      @extend .w-100;
    }
    .btn{
      white-space: nowrap;
    }
    .close{
      margin: 0;
      @extend .ml-1;
      @extend .py-1;
      border: 3px solid $primary;
      opacity: 1;
      color:$primary;
    }
  }
  .modal-dialog{
    @extend .m-0;
    max-width: 100%;
    background-color: $body-bg;
    @extend .vh-100;
    .modal-body{
      background-color: $settings-body-bg;
    }
  }
  .modal-content{
    background-color: transparent;
  }
  .nav-colours{
    .btn{
      @extend .p-3;
      @extend .mb-1;
      border: 2px solid $white;
      position: relative;
      &.active{
        &:before{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: $icomoon-font-family;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          content: $bs-icon-checkmark;
        }
      }
    }
    ._black{
      background-color: $black;
    }
    ._cyan{
      background-color: $cyan;
    }
    ._green{
      background-color: $green;
    }
    ._orange{
      background-color: $orange;
    }
    ._red{
      background-color: $red;
    }
    ._violet{
      background-color: $violet;

    }
    ._white{
      background-color: $white;
    }
    ._yellow{
      background-color: $yellow;
    }
  }


  .toggle-settings {
    .list-group-flush {
      .list-group-item {
        padding: 10px 0;
      }
      &:first-child {
        .list-group-item:first-child {
          border-top-width: $list-group-border-width;
          border-top-color: $list-group-border-color;
          border-top-style: solid;
        }
      }
      &:last-child {
        .list-group-item:last-child {
          border-bottom-width: $list-group-border-width;
          border-bottom-color: $list-group-border-color;
          border-bottom-style: solid;
        }
      }
      .custom-switch{
        //line-height:$custom-switch-indicator-size;
        .custom-control-label{
          width: 100%;
          text-align: right !important;
          &::before{
            background-color: $custom-switch-bg;
            border: none;
            content: 'Off';
            color: $white;
            font-size: rem-calc(16);
            padding: 4px 8px;
            width: 4rem;
            //font-weight: bold;
           // height: $custom-switch-indicator-size;
          }
          &::after{
           //background-color: $white;
            left: -3.8rem;
            //height: $custom-switch-indicator-size - 10px;
          }
        }
       .custom-control-input:checked ~ .custom-control-label::after{
          transform: translateX(2.1rem);
        }
        .custom-control-input:checked ~ .custom-control-label::before{
          background-color: $custom-switch-active-bg;
          content: 'On';
          text-align: left;
          color: $custom-control-indicator-bg;
        }
      }
    }


  }
  #MicrosoftTranslatorWidget{
    #WidgetLauncher{
      width: 100%;
    }
    #LauncherTranslatePhrase{
      border: none;
      border-radius: 5px;
      padding: 15px 10px;
      &:hover{
        background-color: darken($primary, 10%) !important;
      }
    }
    #LauncherLogo{
      display: none;
    }
  }

}
#WidgetFloaterPanels{
  width: 100%;
  max-width: 240px;
  background-color: transparent !important;
  &.LTRStyle{
    box-shadow: none !important;
  }
  #WidgetTranslateWithSpan{
    display: none !important;
  }
  span{
    &.DDSActive{
      background-color: $primary;
    }
    &.DDStyle{
      background-color: $primary;
      border-bottom:none ;
      font-size: 18px;
      border-radius: 5px;
      height: 46px;
      line-height: 43px;
      padding: 0 13px;
      color: $white !important;
    }
  }
  #WidgetFloater{
    width: 100%;
    border: none !important;
    height: auto;
  }
  #CTFLinksPanel{
    display: none !important;
  }
  #WidgetLogoPanel{
    background-color: transparent;
    margin-top: -26px;
  }
  #FloaterProgressBar{
    display: none !important;
  }
  #LanguageMenuPanel{
    background-color: transparent !important;
  }

  #WidgetCloseButton, #WidgetCloseButtonCollapsed{
    background-color:$primary;
    border-radius: 5px 5px 0 0;
    margin-right: 5px;

  }
  span.DDStyle:hover, span.DDStyle:focus{
    background-color: darken($primary, 10%) !important;
  }
  div.DDStyle{
    border-color: $primary;
  }
}


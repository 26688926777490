.promo-banner{
  background-color: $promo-banner-bg;
  color: $promo-banner-color;
  @extend .text-center;
  a{
    color: $promo-banner-color;
  }
  p, h2{
    margin-bottom: 0;
    font-weight: normal;
    color: $promo-banner-color;
  }
  .btn{
    background-color: $promo-banner-btn;
    color: $promo-banner-btn-color;
    font-weight: bold;
  }
  a{
    text-decoration: underline;
  }
}
.modal-header{
  .close{
    margin: 0;
    @extend .ml-1;
    @extend .py-1;
    border: 2px solid $primary;
    opacity: 1;
    color:$primary;
  }
}

.modal-body{
  background-color: $settings-body-bg;
}
.footer{
  font-size: rem-calc(14);
  .footer-logo{
    max-width: 200px;
    max-width: min(200px, 50vw);
    width: 100%;
  }
  .footer-nav{
    a{
      text-decoration: underline;
    }
    p{
      margin: 0;
    }
  }
  .btn{
    font-size: rem-calc(14);
    text-decoration: underline;
  }
}
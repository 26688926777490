//font
@import url('https://fonts.googleapis.com/css?family=Roboto:Roboto:300,400, 500, 700');

// Variables
@import 'settings/mixins';

// Color system
@import "settings/global-variables";

//$primary:       #2f52eb !default;
//$secondary:     #4c8880 !default;

$primary:       #6EB3B1 !default;
$secondary:     #292D80 !default;

$component-active-color:                    $white !default;
$component-active-bg:                       $secondary !default;
$custom-control-indicator-border-color:     $white !default;
$custom-switch-active-bg:                   $primary !default;
$custom-switch-bg:                          $secondary !default;


$logo-name:   $black;
$logo-title:  $primary;
$logo-bg:     $primary;
$logo-body:   $black;
$logo-feet:   $black;

// Body
$line-bg:         $secondary;
$bullets:         $secondary;
$text-color:      $secondary;

//body
$body-bg:                   $off-white !default;
$body-color:                darken($text-color, 40%) !default;
$border-color:                $line-bg !default;

$settings-body-bg: lighten($secondary, 40%);

$headings-color:              $secondary !default;
// Promo banner
$promo-banner-bg:           $theme-green;
$promo-banner-color:        $white;
$promo-banner-btn:          $white;
$promo-banner-btn-color:    $secondary;

// Navs
$navbar-color:                $text-color !default;
$navbar-hover-color:          rgba($text-color, .7) !default;
$navbar-active-color:         rgba($secondary, .7) !default;

//Navbar
$navbar-light-color:          $navbar-color !default;
$navbar-light-hover-color:    $navbar-hover-color!default;
$navbar-light-active-color:   $navbar-active-color!default;

$nav-search-form-bg:          $black;
$nav-search-form-color:       $white;

//Breadcrumb
$breadcrumb-divider-color:          $black !default;
$breadcrumb-active-color:           $black !default;

//$breadcrumb-divider-color:          #C23585 !default;
//$breadcrumb-active-color:           #FFDD00 !default;

//input
$input-border-color:                    $line-bg !default;
$input-placeholder-color:               $line-bg !default;

// List
$list-group-border-color:           rgba($secondary, .8) !default;

//hr
$hr-border-color:             rgba($secondary, .1) !default;

//links
$link-color:                              darken($primary, 15%);
$link-hover-color:                        darken($primary, 30%);

@import 'settings/variables';

// venders
@import '~bootstrap/scss/bootstrap';


//components
@import "components/components";


// Global
@import "global";

body.isInvert{
  background: $black;
  .accessibilityControls{
    p{
      color: $black;
    }
    .custom-switch{
      color: $black;
    }
  }
}
.btn-primary {
  background-color: #FFDD00;
  border-color: #FFDD00;
}

.btn-pink {
  background-color: $pink;
  border-color: $pink;
  color:white;
}

.home-newsletters {
  h2, h3 {
    color: #e54096;
  }
  .media.mb-1 {
    border: 3px solid #e54096;
  }
}

.container .promo-banner{
  background-color: $secondary;
}

#enquiry_form .form-control{
  background-color: #67c6f2 !important;
  border: 4px solid #48A3D6 !important;
}
//Pages


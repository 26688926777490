.breadcrumb-item{
  + .breadcrumb-item {
    &::before {
      font-family: $icomoon-font-family;
      color: $white;
      background: $black;
      border-radius: 20px;
      padding: 2px;
      line-height: normal;
      margin-right: 5px;
    }
  }
}
h6{
  @extend .text-uppercase;
  @extend .mb-2;

  //font-size: rem-calc(14);
}
.blockquote{

}

.table{
  font-weight: normal;
}
.page-title{
  @extend .mb-5;
  .title{
    @extend .mb-3;
  }
}
hr{
  @extend .my-4;
}

dl{
  dt{

  }
  dd{
    @extend .m-0;
    p{
      @extend .m-0;
    }
  }
}

.isUnderLine{
  a{
    text-decoration: underline ;
  }
  .btn{
    text-decoration: underline;
  }
}

.isInvert{
  filter: invert(1);
}
.general-content{
  img{
    max-width: 100%;
  }
}
.btn-black{
    background: black;
    color: white;
}
.btn-black:hover{
    background: black;
    color: white;
}

.btn-green{
    background: #6EB3B1;
    color: white;
}

.btn-green:hover{
    background: #6EB3B1;
    color: white;
}

.multicolumn {
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}

// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

/*
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    display: block;
    width: 5rem;
    height: auto;
    pointer-events: none;
    content: "";
    background-color: $secondary;
    border: 0;
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 47px;
    display: block;
    width: 3rem;
    height: auto;
    content: "";
    background: $body-bg;
  }


}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before{
  background-color: $primary;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after{

  right: 14px;
}
*/



// Custom control indicators
//
// Build the custom controls out of pseudo-elements.

/*
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: "";
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: "";
    background: no-repeat 50% / #{$custom-control-indicator-bg-size};
  }
}
*/


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
  .custom-control-label::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      //background-image: $custom-checkbox-indicator-icon-checked;
      background: none;
      content: $bs-icon-checkmark;
      font-family: $icomoon-font-family;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 2;
      color: #fff;
      text-align: center;
    }
  }
  .custom-control-input:indeterminate ~ .custom-control-label {
    &::before {
      border-color: $custom-checkbox-indicator-indeterminate-border-color;
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
    }
    &::after {
      background-image: $custom-checkbox-indicator-icon-indeterminate;

    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
    &:indeterminate ~ .custom-control-label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

/*
.custom-switch {
  padding-left: 0;
  .custom-control-label {
    width: 100%;
    padding: 10px 0;
    &:before {
      right: 0;f
      left: auto;
      top: 0;
      bottom: 0;
      height: auto;
    }
    &:after {
      right: 31px;
      left: auto;
    }
  }
}*/

// Global colors variables used multiple files
// Color Contrast Checker : https://webaim.org/resources/contrastchecker/
//
// Colors
$white:         #fff; // AA, AAA Pass
$black:         #000; // AA, AAA Pass
$green:         #75fa53; // AA, AAA Pass
$cyan:          #73fbfd; // AA, AAA Pass
$red:           #eb3323; // AA Pass, AAA Fail
$orange:        #ee732e; // AA, AAA Pass
$yellow:        #f9d849; // AA, AAA Pass
$violet:        #a230f6; // AA, AAA Fail
$off-white:     #ffffff;
$pink:          #C23585;
$twitter:       #3c9cd8;
$pinterest:     #d0202b;
$facebook:      #395aa1;
$theme-green:    #1b9390;

